import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="section__404 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h1>404</h1>
        <p>Oops! Something is wrong.</p>
        <Link class="btn btn__primary" to={`/`}>
          {' '}
          Go back in initial page, is better.
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
